import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
// import { ElMessageBox, ElMessage } from 'element-plus'
import { ElMessage } from 'element-plus'
import store from '@/store'
import {getToken, removeToken} from '@/utils/auth'
import router from "@/router";

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        // console.log('store:',store);
        // console.log('store.getters.token:',store.getters.token);
        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            // config.headers['X-Token'] = getToken()
            config.headers['Authorization'] = 'Bearer '+ getToken()
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        console.log('res:',res)
        // console.log('开始拦截返回:',response)
        //增加下载文件处理
        if(response.status === 200 && response.headers['content-type']==="application/force-download"){
            return response
        }


        // if the custom code is not 20000, it is judged as an error.
        // if (res.code !== 20000) {
        if (res.code !== 200) {
            ElMessage({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })



            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
            if (res.code === 401) {
                removeToken()
                router.push('/login').then()

                // to re-login
                // ElMessageBox.confirm('请重新登录', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(() => {
                //     // store.dispatch('user/resetToken').then(() => {
                //     //     location.reload()z
                //     // })
                //     //跳转到登录页重新登录
                //      router.push('/login').then()
                // })
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {

            if(res.message&&res.message!==''&&res.message.length>0){
                ElMessage({
                    message:res.message,
                    type:'success'
                })
            }

            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        ElMessage({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
