import request from '@/utils/request'

export function login(data){
  console.log('login fuck:',data)
    return request({
        url:'/api/sys/login',
        method:'post',
        data:data,
    })
}

export function getInfo(){
    return request({
        url:''
    })
}

export function logout(){

}

export function tokenCheck(){
    return request({
        url:'/api/sys/auth/token-check',
        method:'get',
    })
}

export const getProfile = () =>{
    return request({
        url:'/api/sys/user/profile',
        method:'get'
    })
}
