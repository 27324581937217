import {login as api_login} from '@/api/sys/user'
// import {getToken} from '@/utils/auth'
import {setToken, getToken, removeToken} from "../../utils/auth";
import {getProfile} from "@/api/sys/user";

const getDefaultState = () =>{
    return {
        token:getToken(),
    }
}

//刷新后会丢掉state 所以要再从cookie里面获取一次
//todo:暂时这样 完成erp再换成全局state保存浏览器
const state = getDefaultState()

const getter = {}

const mutations = {
    SET_TOKEN:(state,token)=>{
        console.log('mutations.set_token',state,token)
        state.token = token
    },
    SET_PROFILE:(state,profile)=>{
        state.profile = profile
    }
}

const actions = {
    //用户登录调用这个ACTION
    login({commit},p_data){
        console.log('store.user.actions.login')

        let login_data = {}
        login_data = p_data
        //todo:暂时不加密登录请求参数


        // const user = encryption(
        //     {
        //         data: userInfo,
        //         type: 'Aes',
        //         key: 'dgqd-key',
        //         param: ['username', 'password']
        //     }
        // )
        // console.log('user encrypt : ', user)
        // const { username, password } = user


        return new Promise((resolve,reject)=>{
            api_login(login_data).then(resp=>{
                console.log('login resp:',resp)
                const token = resp.data.token
                commit('SET_TOKEN',token)
                setToken(token)
                resolve()
            }).catch(err=>{
                reject(err)
            })
        })

    },
    //退出登录处理
    logout(){
        return new Promise((resolve)=>{
            removeToken()
            resolve()

        })
        // this.$router.
    },
    // getProfile({commit,state}){
    getProfile({commit}){
        return new Promise(((resolve, reject) => {
            getProfile().then(resp=>{
                if(!resp.data){
                    return reject('用户信息获取异常')
                }
                commit('SET_PROFILE',resp.data)
                resolve(resp.data)
            }).catch((err=>{reject(err)}))
        }))
    }
}


export default {
    namespaced:true,
    state,
    getter,
    mutations,
    actions,
}
