<template>
    <div style="display:flex;height:100%;">
        <!-- 左侧菜单栏 -->
<!--        <div style="width:208px;">-->

      <div class="btn-show-menu" @click="showMenuTap()">
        <div style="padding:12px;background: #FFFFFF;border-radius: 50%;position: fixed;z-index: 2000;">
          <div style="width: 16px;height: 2px;background: #283443;border-radius: 4px"></div>
          <div style="width: 16px;height: 2px;background: #283443;border-radius: 4px;margin: 4px 0;"></div>
          <div style="width: 16px;height: 2px;background: #283443;border-radius: 4px;"></div>
        </div>
      </div>

        <div :class="[side_collapse ? 'aside collapse':'aside',show_menu?'':'hide-menu']">
<!--            <aside :class="side_collapse ?'body-aside.collapse' : 'body-aside'" style="">-->
                <aside :class="side_collapse ?'body-aside collapse' : 'body-aside'" style="">

    <!--            <router-link to="main1">left main1</router-link>-->
    <!--            <router-link to="main2">left main2</router-link>-->
                <el-menu router :default-active="$route.path" active-text-color="#ffffff" background-color="#001529" text-color="hsla(0,0%,100%,.65)" :collapse="side_collapse" style="flex:1;">
                    <el-sub-menu index="1">
                        <template #title>
                          <el-icon><i class="gold icon-user-list" /></el-icon>
                            <span>团队管理</span>
                        </template>
                        <el-menu-item index="/cmp/user-list"><el-icon><i class="gold icon-user" /></el-icon>
                          账号列表</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="2">
                        <template #title>
                            <el-icon><i class="gold icon-account" /></el-icon>
                            <span>账目</span>
                        </template>
                      <el-menu-item index="/cmp/material-list"><el-icon><i class="gold icon-material" /></el-icon>原料记录</el-menu-item>
                        <el-menu-item index="/cmp/process-list"><el-icon><i class="gold icon-process" /></el-icon>加工记录</el-menu-item>
                        <el-menu-item index="/cmp/product-list"><el-icon><i class="gold icon-product" /></el-icon>成品记录</el-menu-item>
                        <el-menu-item index="/cmp/sales-list"><el-icon><i class="gold icon-sale" /></el-icon>销售记录</el-menu-item>
                    </el-sub-menu>

                </el-menu>
                    <el-menu>
                        <el-menu-item v-if="!show_menu" index="4" @click="sideCollapseTap()">
                            <i v-if="!side_collapse" class="el-icon-s-fold"></i>
                            <i v-else class="el-icon-s-unfold"></i>
<!--                            <template #title>导航四</template>-->
                        </el-menu-item>
                    </el-menu>
            </aside>
        </div>
        <!-- 主页面 -->
<!--        <div style="flex:1;width:100%;">-->
            <div style="flex:1;width:calc(100% - 280px);transition:all .5s linear;">
<!--            <div style="float:left;width:100%;">-->
<!--                <div style="background-color: #ffffff;">-->
<!--                    <el-tabs type="card">-->
<!--                        <el-tab-pane label="tabs_1"></el-tab-pane>-->
<!--                        <el-tab-pane label="tabs_2"></el-tab-pane>-->
<!--                        <el-tab-pane label="tabs_3"></el-tab-pane>-->
<!--                        <el-tab-pane label="tabs_4"></el-tab-pane>-->
<!--                        <el-tab-pane label="tabs_5"></el-tab-pane>-->
<!--                    </el-tabs>-->
<!--                </div>-->
                <router-view></router-view>
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "body",
        data(){
            return {
                side_collapse:false,
                show_menu:false
            }
        },
        methods:{
          showMenuTap(){
            this.show_menu = !this.show_menu
            if (this.show_menu){this.side_collapse=true}
          },
          sideCollapseTap(){
            this.side_collapse = !this.side_collapse
          }
        }
    }
</script>

<style scoped>
    /deep/.el-sub-menu__title{height:40px;line-height:40px;}
    /*/deep/.el-menu,.el-menu--collapse{ transition: all 0.3s linear;}*/
    .el-sub-menu .el-menu-item {
        height: 40px;
        line-height: 40px;
        padding: 0 45px;
        min-width: 200px;
        /*transition: all 0.3s linear;*/
    }
    .el-menu-item{
        height:40px;
        line-height:40px;
    }
    .el-menu{border-right: 0px;}
    .body-aside::-webkit-scrollbar{width:6px;}
    .body-aside::-webkit-scrollbar-thumb {
        background: hsla(0,0%,100%,.2);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px hsl(0deg 0% 100% / 5%);
    }
    .body-aside::-webkit-scrollbar-track {
        background: hsla(0,0%,100%,.15);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px rgb(37 37 37 / 5%);
    }
    /deep/.el-tabs__header{margin-bottom: 0;}


    .body-aside{
        transition: all 0.3s linear;
        width:208px;
        position: fixed;
        /*height: 100%;*/
        background:#001529;overflow:hidden auto;box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
        display:flex;
        flex-direction:column;
        bottom: 0;
        top: 48px;


    }
    .body-aside.collapse{
        transition: all 0.3s linear;
        /*width:auto;*/
        width:68px; /* 这里要设置一个宽度 否则动画不生效 不能用auto */
        /*width:auto;*/

    }

    .aside{
        transition: all 0.3s linear;
        width:208px;
    }
    .aside.collapse{
      width:68px;
      transition: all 0.3s linear;
    }
    .btn-show-menu{height: 48px; width: 48px;display: flex;justify-content: center;align-items: center;position: absolute;display: none}
    .hide-menu{display: inline;}
    @media screen and (max-width:1200px){
      .btn-show-menu{height: 48px; width: 48px;display: flex;justify-content: center;align-items: center;position: absolute;}
      .hide-menu{display: none}
    }
</style>
