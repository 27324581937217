
// import VueRouter from 'vue-router'
import { createRouter, createWebHashHistory} from 'vue-router';
// import Left from '@/components/nav/left'
// import Top from '@/components/nav/top'
import Layout from '@/views/layout'
import Body from '@/views/body'
// import Main from '@/views/main'


const _routes = [
    {
        path:'/',
        redirect:'/dashboard'
    },
    {
        path:'/',
        component:Layout,
        children:[
            {
              path:'/',
              component:Body,
              children:[
                  {
                    path:'/dashboard',
                    component:() => import('@/views/dashboard'),
                  }
              ]
            }
        ]
    },
    {
        path:'/',
        component:Layout,
        children: [
            {
              path:'/cmp',
              component: Body,
              children: [
                  {
                      path:'user-list',
                      components:{
                          default:()=>import('@/views/cmp/user-list'),
                      }
                  },
              ]
            },
        ],
    },

    {
        path:'/',
        component:Layout,
        children: [
            {
                path:'cmp',
                component:Body,
                children:[
                  {
                      path:'material-list',
                      component:()=>import('@/views/cmp/material-list')
                  }
                ]
            },
            {
              path:'cmp',
              component:Body,
              children:[
                {
                  path:'process-list',
                  component:()=>import('@/views/cmp/process-list')
                }
              ]
            },
            {
              path:'cmp',
              component:Body,
              children:[
                {
                  path:'product-list',
                  component:()=>import('@/views/cmp/product-list')
                }
              ]
            },
            {
              path:'cmp',
              component:Body,
              children:[
                {
                  path:'sales-list',
                  component:()=>import('@/views/cmp/sales-list')
                }
              ]
            },
        ]
    },

    // {
    //     path:'/',
    //     component:Layout,
    //     children: [
    //         {
    //           path:'/cmp',
    //           component: Body,
    //           children: [
    //               {
    //                   path:'user-list',
    //                   components:{
    //                       default:()=>import('@/views/cmp/user-list'),
    //                   }
    //               },
    //           ]
    //         },
    //     ],
    // },
    {
        path:'/login',
        component:()=>import('@/views/login')
    },
]

export const router = createRouter({
    history:createWebHashHistory(),
    routes:_routes,
})

export default router


